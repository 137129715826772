import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ConfettiGenerator from 'confetti-js';
import html2canvas from 'html2canvas';
import io from 'socket.io-client';

const BingoCard = ({ theme }) => {
  const [tiles, setTiles] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [winMessageShown, setWinMessageShown] = useState(false);
  const [liveUsers, setLiveUsers] = useState('Loading...');
  const logos = {
    default: "https://cdn.wanshow.bingo/resources/images/wanshowbingo-w.png", // WHITE WAN SHOW BINGO / LOGO DEFAULT
    floatplane: "https://cdn.wanshow.bingo/resources/images/wanshowbingo-w.png", // WHITE WAN SHOW BINGO / LOGO DEFAULT
    ltx23: "https://cdn.wanshow.bingo/resources/images/ltxexpobingo.png", // LTX 2023 LOGO / THEME
    bread: "https://cdn.wanshow.bingo/resources/images/wanshowbingo-bread.png", // BREAD LOGO / THEME
    winter: "https://cdn.wanshow.bingo/resources/images/wanshowbingo-christmas.png", // CHRISTMAS WAN SHOW BINGO
    afterdark: "https://cdn.wanshow.bingo/resources/images/afterdark-light.png", // AFTERDARK LOGO / THEME
    bringus: "https://cdn.wanshow.bingo/resources/images/bringus-bingo.png", // AFTERDARK LOGO / THEME
  };

  const logoUrl = logos[theme] || logos.default; // Fallback to default if theme is not found

  useEffect(() => {
    const fetchAndRandomizeTiles = async () => {
      // Attempt to retrieve cached card and clicked tiles
      const cachedCard = localStorage.getItem('bingoCard');
      const clickedTiles = JSON.parse(localStorage.getItem('clickedTiles')) || [];
    
      if (cachedCard) {
        let cachedTiles = JSON.parse(cachedCard);
    
        // Check if 'Free Space' already exists in the middle, if not, add it
        if (cachedTiles.length === 24) {
          cachedTiles.splice(12, 0, 'Free Space');
        }
    
        cachedTiles = cachedTiles.map((tile, index) => 
          clickedTiles.includes(index) ? `${tile} clicked` : tile
        );
    
        setTiles(cachedTiles);
      } else {
        try {
          const response = await fetch('https://socket.wanshow.bingo/api/tiles');
          const data = await response.json();
          let tileNames = shuffleArray(data.map(tile => tile.name));
    
          // Insert 'Free Space' tile at the middle position
          tileNames.splice(12, 0, 'Free Space');
        
          setTiles(tileNames);
          localStorage.setItem('bingoCard', JSON.stringify(tileNames));
        } catch (error) {
          console.error('Failed to fetch tiles:', error);
        }
      }
    };        

    fetchAndRandomizeTiles();

    // WebSocket setup
    const socket = io('https://socket.wanshow.bingo:3015');
    socket.on('connect', () => console.log('Connected to the server'));
    socket.on('liveUsers', data => setLiveUsers(`Current Live Users: ${data.liveUsers}`));
    socket.on('disconnect', () => console.log('Disconnected from the server'));

    return () => socket.disconnect();
  }, [refreshKey]);

  const shuffleArray = array => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const handleRefreshClick = () => {
    localStorage.removeItem('bingoCard');
    localStorage.removeItem('clickedTiles');
    setRefreshKey(prevKey => prevKey + 1);
    setWinMessageShown(false);
  };

  const handleTileClick = index => {
    const updatedTiles = [...tiles];
    updatedTiles[index] = updatedTiles[index].includes('clicked') ? 
                          updatedTiles[index].replace(' clicked', '') : 
                          `${updatedTiles[index]} clicked`;

    setTiles(updatedTiles);

    const clickedTiles = updatedTiles
      .map((tile, i) => tile.includes('clicked') ? i : -1)
      .filter(index => index !== -1);

    localStorage.setItem('clickedTiles', JSON.stringify(clickedTiles));

    if (!winMessageShown) checkForWinner(updatedTiles);
  };

  const checkForWinner = currentTiles => {
    const winningCombinations = [
      [0, 1, 2, 3, 4], [5, 6, 7, 8, 9], [10, 11, 12, 13, 14], [15, 16, 17, 18, 19], [20, 21, 22, 23, 24],
      [0, 5, 10, 15, 20], [1, 6, 11, 16, 21], [2, 7, 12, 17, 22], [3, 8, 13, 18, 23], [4, 9, 14, 19, 24],
      [0, 6, 12, 18, 24], [4, 8, 12, 16, 20]
    ];

    for (const combination of winningCombinations) {
      if (combination.every(index => currentTiles[index].includes('clicked'))) {
        displayWinMessage();
        break;
      }
    }
  };

  const displayWinMessage = () => {
    setWinMessageShown(true);

    const confettiSettings = { target: 'my-canvas' };
    new ConfettiGenerator(confettiSettings).render();

    Swal.fire({
      icon: 'success',
      title: 'Congratulations!',
      html:`
        <p>You Win!</p>
      `,
      showConfirmButton: false,
      customClass: {
        container: 'win-message-container',
      },
    });

    // Mark that the win message has been shown
    setWinMessageShown(true);
  };

  // Function to continue playing after winning
  const handleContinueClick = () => {
    // Hide the win message
    Swal.close();
  };

  // Function to capture a screenshot of the card
  const capture = () => {
    // Get the specific element you want to capture (the card)
    const elementToCapture = document.getElementById('game');

    // Hide the elements you want to exclude from the screenshot
    const elementsToHide = elementToCapture.querySelectorAll('.refresh-button, .continue-button, .screenshot-button');
    elementsToHide.forEach(function (element) {
      element.style.display = 'none';
    });

    // Use html2canvas to capture the modified element
    html2canvas(elementToCapture).then(function (canvas) {
      // Show the hidden elements again
      elementsToHide.forEach(function (element) {
        element.style.display = '';
      });

      // Download the screenshot as an image
      const link = document.createElement('a');
      link.download = 'bingo-card-screenshot.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  // Generate a 5x5 bingo grid
  const gridTiles = [];
  for (let i = 0; i < 25; i++) {
    const tileClasses = tiles[i] || 'Loading...'; // Get the class names for the tile
    gridTiles.push(
      <div
        key={i}
        className={`item ${tileClasses} ${i === 12 ? 'free-space' : ''}`}
        onClick={() => handleTileClick(i)} // Attach click handler to each tile
      >
        {tiles[i]}
      </div>
    );
  }

  return (
    <div className="bingo-game-container">
      <canvas id="my-canvas"></canvas>
      &nbsp;
      <div className="snowflake-container"></div>
      <div className="snowflake-container"></div>
      <div className="snowflake-container"></div>
      <div className="snowflake-container"></div>
      <div className="star star1"></div>
      <div className="star star2"></div>
      <div className="star star3"></div>
      <div className="star star4"></div>
      <div className="star star5"></div>
      <div className="star star6"></div>
      <div className="star star7"></div>
      <div className="star star8"></div>
      <div className="star star9"></div>
      <div className="star star10"></div>
      <div className="star star11"></div>
      <div className="star star12"></div>
      <div className="star star13"></div>
      <div className="star star14"></div>
      <div className="star star15"></div>
      <div id="game">
        <div className="logo-refresh-container">
          <div className="inner-sheets">
            <a href="https://sheet.wanshow.bingo" target="_blank"><button>Follow along with our Live Google Sheets doc!</button></a>
          </div>
          <div className="title">
            <img src={logoUrl} width="35%" alt="WAN Show Bingo!" />
          </div>
          <div className="inner-rfrsh-btn">
          <a className="sheet-link">
            <button id="refreshButton" className="refreshButton" onClick={handleRefreshClick}>
              Refresh Card! <i className="fas fa-sync-alt refresh-icon"></i>
            </button>
          </a>
          </div>
        </div>
        <div id="board">
          <div className="bingo-container">
            <div className="bingo-card" id="bingo-card"></div>
            <div className="board">{gridTiles}</div>
          </div>
        </div>
        <p className="user-count">{liveUsers}</p>
        <p className="the-team">View The <a href="https://www.wanshow.bingo/contributors" target="_blank" title="Active Team & Contributors">Active Team & Contributors</a></p>
        <button onClick={capture}>Capture and Download Screenshot</button>
      </div>
    </div>
  );
};

export default BingoCard;
