import React, { useEffect, useState } from 'react';
import '../../assets/css/BingoCard.css';

const WhenPlane = () => {
  const [timerMessage, setTimerMessage] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      fetch('https://socket.wanshow.bingo/whenplane')
        .then(response => response.json())
        .then(data => {
          updateTimer(data);
        })
        .catch(error => {
          console.error('An error occurred while fetching the timer:', error);
        });
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  const updateTimer = (data) => {
    let message = '';
    const now = new Date();
    const wanTime = new Date(data.nextWan);

    if ((data.isLive.twitch.isLive && data.isLive.twitch.isWAN) || (data.isLive.youtube.isLive && data.isLive.youtube.isWAN)) {
      const startedTime = new Date(data.isLive.twitch.started || data.isLive.youtube.started);
      const liveDuration = now - startedTime;
      message = `The WAN show has been live for ${formatDuration(liveDuration)}\nStarted at ${startedTime.toLocaleTimeString()}`;
    } else if (now < wanTime) {
      const countdownDuration = wanTime - now;
      message = `The WAN Show starts in ${formatDuration(countdownDuration)}`;
    } else {
      const lateDuration = now - wanTime;
      message = `${lateDuration > 0 ? 'WAN Show is Late by: ' : 'The WAN Show is offline'} ${formatDuration(Math.abs(lateDuration))}`;
    }

    setTimerMessage(message);
  };

  const formatDuration = (duration) => {
    const days = Math.floor(duration / 86400000);
    const hours = Math.floor((duration % 86400000) / 3600000);
    const minutes = Math.floor((duration % 3600000) / 60000);
    const seconds = Math.floor((duration % 60000) / 1000);
    return `${days > 0 ? days + 'd ' : ''}${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div id="whenplane">
      <a href="https://go.wanl.ink/whenplane" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <div id="wan-show-timer" className="card p-4 inline-block countdown-box text-left svelte-1xheok0">
          <h2 dangerouslySetInnerHTML={{ __html: timerMessage }}></h2>
          <p className="provider-text">Provided by WhenPlane.com</p>
        </div>
      </a>
    </div>
  );
};

export default WhenPlane;
