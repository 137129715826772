import io from 'socket.io-client';

const setupWebSocketConnection = (onBingoNotification, onStateChange) => {
  const socket = io('wss://mq.thewandb.com', {
    transports: ['websocket'],
  });

  socket.on('connect', () => {
    console.log('Connected to WebSocket server');
    socket.emit('message', JSON.stringify({
      type: 2,
      payload: 'bingo'
    }));
  });

  socket.on('bingo', (data) => {
    const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
    if (parsedData.now === "TRUE") {
      onBingoNotification(parsedData);
    }
  });

  socket.on('state', (data) => {
    const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
    console.log('Received state data:', parsedData);
    if (parsedData.thumbnail) {
      console.log('Thumbnail URL:', parsedData.thumbnail);
    } else {
      console.log('No thumbnail URL in the state data');
    }
    onStateChange(parsedData);
  });  

  socket.on('disconnect', () => {
    console.log('Disconnected from WebSocket server');
  });

  socket.on('connect_error', (error) => {
    console.error('Connection error:', error);
  });

  return socket;
};

export default setupWebSocketConnection;