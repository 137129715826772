import React, { useEffect, useState } from 'react';
import setupWebSocketConnection from './wandbNotification.js';
import './wandbNotification.css';

const WandbNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);

  useEffect(() => {
    if (!showNotifications) return;

    const socket = setupWebSocketConnection((data) => {
      const notificationMessage = `${data.tile} has been verified ✅`;
      const newNotification = { message: notificationMessage, fadeOut: false };
      setNotifications(prevNotifications => [newNotification, ...prevNotifications]);

      setTimeout(() => {
        setNotifications(prevNotifications => 
          prevNotifications.map((notif) => 
            notif === newNotification ? { ...notif, fadeOut: 'partial' } : notif // Set to 'partial' instead of true
          )
        );
      }, 5000);
    });

    return () => {
      if (socket) socket.disconnect();
    };
  }, [showNotifications]);

  const closeNotification = (index) => {
    setNotifications(prevNotifications => 
      prevNotifications.filter((_, idx) => idx !== index)
    );
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div className="notification-container">
      {showNotifications && notifications.map((notification, index) => (
        <div key={index} className={`notification-message ${notification.fadeOut === 'partial' ? 'fade-to-partial' : ''}`}>
          {notification.message}
          <i className="fa fa-times close-icon" onClick={() => closeNotification(index)}></i>
        </div>
      ))}
      <button className="toggle-notifications-button" onClick={toggleNotifications}>
        {showNotifications ? 'Hide All Notifications' : 'Show Notifications'}
      </button>
    </div>
  );
};

export default WandbNotification;
